<style lang="scss">
  @import './CustomAlert.scss';
</style>

<template>
  <div v-if="show" class="custom-alert-container">
    <div class="alert-backdrop"></div>
    <div class="alert">
      <div class="alert-header">
        <h1>{{title}}</h1>
      </div>
      <div class="alert-body">
        <div class="form-container text-center message" v-html="text"></div>
        <div class="form-container text-center buttons">
          <button type="button" v-on:click="toggleAlert(false)" v-bind:class="btnClass">Chiudi</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const defaultTitle  = `RTL 102.5 Play`;
const defaultText   = `Si è verificato un errore durante la chiamata al server.<br />Si prega di riprovare.`;
const defaultButtonClass = `tertiary`;

export default {
  name: 'CustomAlert',
  data: () => ({
    title: defaultTitle,
    text: defaultText,
    show : false,
    btnClass: defaultButtonClass,
  }),
  methods: {
    setTitle(newTitle = null){
      this.title = newTitle;
    },
    setText(newText = null){
      this.text = newText;
    },
    setBtnClass(newBtnClass = null){
      this.btnClass = newBtnClass;
    },
    reset() {
      this.title  = defaultTitle;
      this.text   = defaultText;
      this.btnClass = defaultButtonClass;
    },
    toggleAlert(newState = null){
      if (newState === null){
        newState = !this.show;
      }
      this.show = newState;
      this.$emit(newState ? 'alertShown' : 'alertHidden');
    }
  }
}
</script>